export const USER_DATA_REQUEST = "USER_DATA_REQUEST";
export const USER_DATA_SUCCESS = "USER_DATA_SUCCESS";
export const USER_DATA_FAIL = "USER_DATA_FAIL";

export const MEMBERSHIP_REQUEST = "MEMBERSHIP_REQUEST";
export const MEMBERSHIP_SUCCESS = "MEMBERSHIP_SUCCESS";
export const MEMBERSHIP_FAIL = "MEMBERSHIP_FAIL";

export const MEMBERSHIP_DETAILS_REQUEST = "MEMBERSHIP_DETAILS_REQUEST";
export const MEMBERSHIP_DETAILS_SUCCESS = "MEMBERSHIP_DETAILS_SUCCESS";
export const MEMBERSHIP_DETAILS_FAIL = "MEMBERSHIP_DETAILS_FAIL";