export const NEW_TEXTFILE_REQUEST = "NEW_TEXTFILE_REQUEST";
export const NEW_TEXTFILE_SUCCESS = "NEW_TEXTFILE_SUCCESS";
export const NEW_TEXTFILE_FAIL = "NEW_TEXTFILE_FAIL";

export const PERPLEXITY_AI_REQUEST = "PERPLEXITY_AI_REQUEST";
export const PERPLEXITY_AI_SUCCESS = "PERPLEXITY_AI_SUCCESS";
export const PERPLEXITY_AI_FAIL = "PERPLEXITY_AI_FAIL";

export const SAVENEWFILE_REQUEST = "SAVENEWFILE_REQUEST";
export const SAVENEWFILE_SUCCESS = "SAVENEWFILE_SUCCESS";
export const SAVENEWFILE_FAIL = "SAVENEWFILE_FAIL";

export const GET_TEXTFILES_REQUEST = "GET_TEXTFILES_REQUEST";
export const GET_TEXTFILES_SUCCESS = "GET_TEXTFILES_SUCCESS";
export const GET_TEXTFILES_FAIL = "GET_TEXTFILES_FAIL";

export const DELETE_TEXTFILES_REQUEST = "DELETE_TEXTFILES_REQUEST";
export const DELETE_TEXTFILES_SUCCESS = "DELETE_TEXTFILES_SUCCESS";
export const DELETE_TEXTFILES_FAIL = "DELETE_TEXTFILES_FAIL";

export const NEW_TEXTFILE_RESET = "NEW_TEXTFILE_RESET";
export const SAVENEWFILE_RESET = "SAVENEWFILE_RESET";
export const GET_TEXTFILES_RESET = "GET_TEXTFILES_RESET";

export const POST_TRACKING_ACTION_REQUEST = "POST_TRACKING_ACTION_REQUEST";
export const POST_TRACKING_ACTION_SUCCESS = "POST_TRACKING_ACTION_SUCCESS";
export const POST_TRACKING_ACTION_FAIL = "POST_TRACKING_ACTION_FAIL";
export const POST_TRACKING_ACTION_RESET = "POST_TRACKING_ACTION_RESET";

export const GET_PERMISSIONS_REQUEST = "GET_PERMISSIONS_REQUEST";
export const GET_PERMISSIONS_SUCCESS = "GET_PERMISSIONS_SUCCESS";
export const GET_PERMISSIONS_FAIL = "GET_PERMISSIONS_FAIL";
export const GET_PERMISSIONS_RESET = "GET_PERMISSIONS_RESET";

export const POST_PERMISSIONS_REQUEST = "POST_PERMISSIONS_REQUEST";
export const POST_PERMISSIONS_SUCCESS = "POST_PERMISSIONS_SUCCESS";
export const POST_PERMISSIONS_FAIL = "POST_PERMISSIONS_FAIL";
