import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";

import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import moment from "moment";
import { userListAction, Userstatusupdate } from "../Redux/Action/adminAction";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { logout } from "../Redux/Action/authentication-action";
import { useNavigate } from "react-router-dom";

function Userlist() {
  const dispatch = useDispatch();

  const [statusid, setid] = useState("");
  const [isOpen9, setIsOpen9] = useState(false);
  const [usersForRender, setUsersForRender] = useState([]);

  const menuProps = {
    variant: "menu",
    disableScrollLock: true,
  };

  const { success: successUpdate } = useSelector(
    (state) => state.userUpdateReducer
  );

  const { userListData, success: listdone } = useSelector(
    (state) => state.getUserListReducer
  );

  useEffect(() => {
    dispatch(userListAction());
  }, [successUpdate]);

  useEffect(() => {
    let userData = [];

    if (userListData) {
      userListData
        ?.filter((item) => !item.is_superuser)
        ?.map((item, index) => {
          item.username = item.username;
          var utc1 = null;
          if (item.created_at) {
            utc1 = moment(item.created_at).format("MM-DD-yyyy hh:mm a");
          }
          item.created_at = utc1;

          item.status1 = (
            <div className="action-btnss" style={{ display: "flex" }}>
              {item.is_active ? (
                <div className="active_status">
                  <i className="fa fa-solid fa-check" aria-hidden="true"></i>{" "}
                </div>
              ) : (
                <div className="inactive_status">
                  <i className="fa fa-solid fa-times" aria-hidden="true"></i>
                </div>
              )}
            </div>
          );

          item.action = (
            <div className="setting-action-btn" style={{ display: "flex" }}>
              {/* <div style={{ display: "flex" }}> */}
              {/* <button title="delete" className="deletebutt"> */}
              <p
                className="editiconDelete"
                onClick={() => updateHandler(item.is_active, item.id)}
              >
                <i class="fa fa-gear"></i>
              </p>
              {/* </button> */}
              {/* </div> */}
            </div>
          );
          userData.push(item);
        });
    }

    setUsersForRender(userData);
  }, [listdone]);

  const updateHandler = (item, id) => {
    // setStatus(item);

    if (item === true) {
      setStatus("active");
    }
    if (item === false) {
      setStatus("inactive");
    }
    setid(id);
    setmoveFolder1(true);
    // if (window.confirm("Are you sure you want to delete this level?")) {
    //   dispatch(deletelevel(id));
    // }
  };

  const data1 = {
    columns: [
      {
        label: "Username",
        field: "username",
        sort: "asc",
        width: 500,
      },

      {
        label: "Created At",
        field: "created_at",
        sort: "asc",
        width: 500,
      },
      {
        label: "Status",
        field: "status1",
        sort: "asc",
        width: 500,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: usersForRender,
  };

  const [status, setStatus] = React.useState("active");
  const [moveFolder1, setmoveFolder1] = React.useState(false);
  const movedialogclose1 = () => {
    setmoveFolder1(false);
  };

  const useradd = (e) => {
    setStatus(e.target.value);
  };

  const userstatus = () => {
    setmoveFolder1(false);
    dispatch(
      Userstatusupdate(
        {
          action: status === "active" ? "unblock" : "block",
        },
        statusid
      )
    );
    // setTimeout(() => {
    //   dispatch(userListAction());
    // }, 500);
  };

  const [count, setCount] = useState();
  const { userData } = useSelector((state) => state.authReducer);

  const { success: successAdd, addfiledata } = useSelector(
    (state) => state.newTextFileReducer
  );

  const { success: successSave, saveFileData } = useSelector(
    (state) => state.saveNewFileReducer
  );

  useEffect(() => {
    setCount(localStorage.getItem("response_data"));
  }, [successAdd, successSave]);

  const navigate = useNavigate();
  const logoutHandler = () => {
    dispatch(logout());
    navigate("/");
  };

  return (
    <>
      <div class="homepage">
        <div class="homepagepay usr">
          <div class="thanktexthome userlist">
            <Dialog
              className="profileImgDialogagency popupclass "
              open={moveFolder1}
              // onClick={movedialogclose}
            >
              <DialogTitle className="profileImgfolder imgsizefixer">
                <h2 className="createplay-title">User Status</h2>
              </DialogTitle>
              <div className="dialogcontent_and_actions_new">
                <DialogContent>
                  <div>
                    <div className="foldermainDisplayDivsec">
                      <div className="dialogcontent_and_actions_new">
                        <Select
                          open={isOpen9}
                          onOpen={() => {
                            setIsOpen9(true);
                          }}
                          onClose={() => {
                            setIsOpen9(false);
                          }}
                          MenuProps={menuProps}
                          value={status}
                          onChange={useradd}
                          displayEmpty
                          inputProps={{
                            "aria-label": "Without label",
                          }}
                        >
                          <MenuItem value="active">Active</MenuItem>
                          <MenuItem value="inactive"> Inactive</MenuItem>
                        </Select>
                      </div>
                    </div>
                  </div>
                </DialogContent>
                <DialogActions>
                  <div className="cancelButtonnewFolder">
                    <Button className="canceButtonnewPop " onClick={userstatus}>
                      Ok
                    </Button>
                    <button
                      className="canceButtonnewPop bakcolor"
                      onClick={movedialogclose1}
                    >
                      Cancel
                    </button>
                  </div>
                </DialogActions>
              </div>
            </Dialog>
            <h2 className="add-title">Users List</h2>
            <div className="allpage">
              <MDBDataTable
                className="dashbordtable dashbordtable1"
                style={{}}
                responsive
                striped
                bordered
                small
                data={data1}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Userlist;
