import React, { useEffect, useState, useRef } from "react";
import { Frontend_URL } from "../../environment";
import Dropzone from "react-dropzone";
import { useSelector, useDispatch } from "react-redux";
import { InputSwitch } from "primereact/inputswitch";
import sampleFile from "../../assets/algoFiles/Text_file_time_series.txt";
import { SelectButton } from "primereact/selectbutton";
import "./DataTrendPage.css";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { ListBox } from "primereact/listbox";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { AutoComplete } from "primereact/autocomplete";
import { RadioButton } from "primereact/radiobutton";
import { newTestFile } from "../../Redux/Action/newfile-action";

mapboxgl.accessToken =
  "pk.eyJ1IjoiZ2VvdGhhcmEiLCJhIjoiY2xrd21tb2VpMTJ0djNybXliZzhwb29hOCJ9.3hVMlLmgoVu8cOPEH5IsYg"; // Replace with your Mapbox access token

function DataTrendPage(props) {
  const [isAuto, setIsAuto] = useState(false);
  const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() - 7);
    date.setHours(12, 0, 0, 0);
    return date;
  });
  const [endDate, setEndDate] = useState(() => {
    const date = new Date();
    date.setHours(12, 0, 0, 0);
    return date;
  });
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [searchQuery, setSearchQuery] = useState("Seattle, WA (US)");
  const [searchResults, setSearchResults] = useState([]);
  const [analysisLevel, setAnalysisLevel] = useState("medium");
  const [dataSet, setDataSet] = useState("Washington State");
  const [selectedDataSet, setSelectedDataSet] = useState(null);
  const [dataSetSearchResults, setDataSetSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isGettingLocation, setIsGettingLocation] = useState(false);
  const {
    count,
    errorOfFile,
    selectedMethod,
    setSelectedMethod,
    confidenceText,
    showConfidenceText,
    onDrop,
    file,
    errors,
    preWhitening,
    setPreWhitening,
    bootstrap,
    setBootstrap,
    incrementCount,
    validateSubmit,
    decrementCount,
    trackClick,
    setMyVar,
  } = props;

  const options = [
    { label: "Manual", value: false },
    { label: "Auto", value: true },
  ];

  const customStyle = {
    "& .p-selectbutton": {
      borderRadius: "20px",
      overflow: "hidden",
    },
    "& .p-selectbutton .p-button": {
      backgroundColor: "#7867c1",
      border: "none",
      color: "#6c757d",
      padding: "0.5rem 1rem",
    },
    "& .p-selectbutton .p-button.p-highlight": {
      backgroundColor: "#7867c1",
      color: "#ffffff",
    },
  };

  const searchLocation = async (event) => {
    const query = event.query;
    try {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
          query
        )}.json?access_token=${mapboxgl.accessToken}&types=place`
      );
      const data = await response.json();
      const suggestions = data.features.map((feature) => ({
        name: feature.place_name,
        coordinates: feature.center,
      }));
      setSearchResults(suggestions);
    } catch (error) {
      console.error("Error searching locations:", error);
    }
  };

  const onLocationSelect = (event) => {
    const location = event.value;
    setSearchQuery(location.name);

    if (map.current) {
      map.current.flyTo({
        center: location.coordinates,
        zoom: 10,
      });
    }
  };

  const getUserLocation = () => {
    setIsGettingLocation(true);
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          if (map.current) {
            map.current.flyTo({
              center: [longitude, latitude],
              zoom: 10,
            });
          }

          fetch(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${mapboxgl.accessToken}`
          )
            .then((response) => response.json())
            .then((data) => {
              if (data.features && data.features.length > 0) {
                setSearchQuery(data.features[0].place_name);
              }
            })
            .catch(console.error)
            .finally(() => setIsGettingLocation(false));
        },
        (error) => {
          console.error("Error getting location:", error);
          setIsGettingLocation(false);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser");
      setIsGettingLocation(false);
    }
  };

  useEffect(() => {
    if (isAuto && !map.current) {
      const seattleCoords = [-122.3321, 47.6062];

      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v12",
        center: seattleCoords,
        zoom: 10,
      });

      // Add navigation controls
      map.current.addControl(new mapboxgl.NavigationControl(), "top-right");
    }

    return () => {
      if (map.current) {
        map.current.remove();
        map.current = null;
      }
    };
  }, [isAuto]);

  const dataSetOptions = [
    { label: "OoDW Water Quality 1990-1999", value: "wq_90_99" },
    { label: "OoDW Water Quality 2000-2009", value: "wq_00_09" },
    { label: "OoDW Water Quality 2010-2019", value: "wq_10_19" },
    { label: "WASWD Meta Data", value: "waswd" },
    { label: "WASWD Meta Data (2021 revision)", value: "waswd_2021" },
    { label: "Seattle City Light - Diablo flow rates", value: "scl_diablo" },
    { label: "Seattle City Light - Gorge flow rates", value: "scl_gorge" },
    { label: "Seattle City Light - Ross flow rates", value: "scl_ross" },
  ];

  const handleProcess = () => {
    setIsLoading(true);

    // Fetch the sample file and create a proper File object
    fetch(sampleFile)
      .then((response) => response.blob())
      .then((blob) => {
        // Create a File object from the blob
        const file = new File([blob], "Text_file_time_series.txt", {
          type: "text/plain",
        });

        // Create FormData with the file
        const formData = new FormData();
        formData.append("txt_file", file);
        formData.append("confidence", 95);

        // Dispatch the action with the proper FormData
        props.dispatch(newTestFile(formData));

        // Simulate file selection by calling onDrop
        onDrop([file]);

        // Determine wait time based on analysis level
        const waitTime = {
          low: 1000,
          medium: 2000,
          high: 3000,
        }[analysisLevel];

        setTimeout(() => {
          // Reset states after processing
          setIsLoading(false);
          setSearchQuery("Seattle, WA (US)");
          setStartDate(null);
          setEndDate(null);
          setAnalysisLevel("medium");
          setDataSet("Washington State");
          setSelectedDataSet(null);

          // Enable Analysis tab and switch to it
          props.setRunSuccessful(true);
          props.setMyVar("Analysis");
        }, waitTime);
      })
      .catch((error) => {
        console.error("Error processing file:", error);
        setIsLoading(false);
      });
  };

  const validateDateRange = (start, end) => {
    if (!start || !end) return false;
    if (start > end) return false;
    const diffDays = Math.ceil((end - start) / (1000 * 60 * 60 * 24));
    return diffDays >= 1 && diffDays <= 365; // Ensure range is between 1 day and 1 year
  };

  const handleStartDateChange = (e) => {
    const newStartDate = e.value;
    if (validateDateRange(newStartDate, endDate)) {
      setStartDate(newStartDate);
    }
  };

  const handleEndDateChange = (e) => {
    const newEndDate = e.value;
    if (validateDateRange(startDate, newEndDate)) {
      setEndDate(newEndDate);
    }
  };

  return (
    <div
      id="bg-graph-img"
      className="main-graph-image"
      style={{
        backgroundImage: `url(${Frontend_URL}img/runAlgo.png)`,
        minHeight: "100vh",
        padding: "2rem",
        position: "relative",
      }}
      onClick={(e) => trackClick(e)}
    >
      <div
        style={{
          display: "flex",
        }}
      >
        <SelectButton
          value={isAuto}
          onChange={(e) => setIsAuto(e.value)}
          options={options}
          style={{ borderRadius: "20px" }}
          className="mode-switch"
        />
      </div>

      {!isAuto ? (
        <div className="">
          <div className="heading">
            <h1>Import Data</h1>
          </div>
          <div className="">
            <div className="lg:py-6 lg:pr-16">
              <div className="flex  ">
                <div className="flex flex-col items-center mr-4">
                  <div>
                    <div className="flex items-center justify-center w-10 h-10 border rounded-full rounded-border-contnet">
                      1
                    </div>
                  </div>
                  <div className="w-px h-full bg-gray-500 border-steps" />
                </div>
                <div className="pt-1 pb-8 dropzone-part">
                  <p className="mb-2 text-lg font-bold select-steps-contnet">
                    Select import file
                    <span
                      className="info-icon"
                      onClick={() => {
                        const link = document.createElement("a");
                        link.href = sampleFile;
                        link.download = "Text_file_time_series.txt";
                        link.click();
                        fetch(sampleFile)
                          .then((response) => response.blob())
                          .then((blob) => {
                            const file = new File(
                              [blob],
                              "Text_file_time_series.txt",
                              { type: "text/plain" }
                            );
                            onDrop([file]);
                          });
                      }}
                      style={{
                        marginLeft: "8px",
                        cursor: "pointer",
                        color: "#007bff",
                        fontSize: "16px",
                      }}
                    >
                      i
                    </span>
                  </p>

                  <div className="dropzone-content">
                    <Dropzone onDrop={onDrop}>
                      {({ getRootProps, getInputProps }) => (
                        <>
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <label
                              htmlFor="file-input-area"
                              className=" fileupload"
                            >
                              Upload
                            </label>
                            <p className="errorMsg">{errorOfFile}</p>
                          </div>

                          <p className="errorMsg">{errors.file || null}</p>
                        </>
                      )}
                    </Dropzone>
                    <span className="dataText"> {file?.name}</span>
                  </div>
                </div>
              </div>
              <div className="flex part2">
                <div className="flex flex-col items-center mr-4">
                  <div>
                    <div className="flex items-center justify-center w-10 h-10 border rounded-full rounded-border-contnet">
                      2
                    </div>
                  </div>
                  <div className="w-px h-full bg-gray-300  border-steps" />
                </div>
                <div className="pt-1 pb-8 ">
                  <p className="mb-2 text-lg font-bold select-steps-contnet">
                    Select method
                  </p>
                  <div className="methods">
                    <select
                      className="method-select"
                      value={selectedMethod}
                      onChange={(event) =>
                        setSelectedMethod(event.target.value)
                      }
                      required
                    >
                      <option value="Hamed Rao Test">Hamed Rao Test</option>
                      <option value="Hamed Rao Test">Hamed Rao v2</option>
                      <option value="Hamed Rao Test">Hamed Rao v3</option>
                      <option value="Hamed Rao Test">Hamed Rao v4</option>
                      <option value="Yue Wang Test">Yue Wang Test</option>
                      <option value="Yue Wang Test">Yue Wang Test</option>
                      <option value="Yue Wang Test">Yue Wang v3</option>
                      <option value="Yue Wang Test">Yue Wang v4</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="flex">
                <div className="flex flex-col items-center mr-4">
                  <div>
                    <div className="flex items-center justify-center w-10 h-10 border rounded-full rounded-border-contnet">
                      3
                    </div>
                  </div>
                  <div className="w-px h-full bg-gray-300  border-steps" />
                </div>
                <div className="pt-1 pb-8">
                  <p className="mb-2 text-lg font-bold select-steps-contnet">
                    Pre-processing
                  </p>
                  <div className="toggle-switch">
                    <label className="select-toggle-btn">Prewhitening</label>
                    <label class="switch">
                      <input
                        checked={preWhitening}
                        type="checkbox"
                        class="switch-input"
                      />
                      <span
                        onClick={(e) => setPreWhitening(!preWhitening)}
                        class="switch-label"
                        data-on="On"
                        data-off="Off"
                      ></span>
                      <span class="switch-handle"></span>
                    </label>
                  </div>

                  <div className="toggle-switch">
                    <label className="select-toggle-btn">Bootstrap</label>
                    <label class="switch">
                      <input
                        checked={bootstrap}
                        type="checkbox"
                        class="switch-input"
                      />
                      <span
                        onClick={(e) => setBootstrap(!bootstrap)}
                        class="switch-label"
                        data-on="On"
                        data-off="Off"
                      ></span>
                      <span class="switch-handle"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="flex">
                <div className="flex flex-col items-center mr-4">
                  <div>
                    <div className="flex items-center justify-center w-10 h-10 border rounded-full rounded-border-contnet">
                      4
                    </div>
                  </div>
                </div>
                <div className="pt-1 pb-8">
                  <p className="mb-1 text-lg font-bold select-steps-contnet">
                    Confidence
                  </p>
                  <p className="text-gray-700">
                    <div>
                      <div className="plus-minus-input">
                        <button
                          type="button"
                          className="decrement-button"
                          onClick={decrementCount}
                        >
                          -
                        </button>
                        <span className="count-persanttage">{count}%</span>

                        <button
                          type="button"
                          className="increment-button"
                          onClick={incrementCount}
                        >
                          +
                        </button>
                      </div>
                    </div>
                    {showConfidenceText ? (
                      <span>{confidenceText}</span>
                    ) : (
                      <span style={{ opacity: 0 }}>valid</span>
                    )}
                  </p>
                </div>
              </div>
              <div className="spaceonright">
                <button
                  className="submitbtn"
                  type="button"
                  onClick={(e) => validateSubmit(e)}
                >
                  Run
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="">
          <div className="heading">
            <h1>Auto Process</h1>
          </div>
          <div className="lg:py-6 lg:pr-16">
            {/* Section 1: Location */}
            <div className="flex">
              <div className="flex flex-col items-center mr-4">
                <div>
                  <div className="flex items-center justify-center w-10 h-10 border rounded-full rounded-border-contnet">
                    1
                  </div>
                </div>
                <div className="w-px h-full bg-gray-500 border-steps" />
              </div>
              <div className="pt-1 pb-8">
                <p className="mb-2 text-lg font-bold select-steps-contnet">
                  Location
                </p>
                <div className="location-section">
                  <div className="search-container">
                    <span className="p-input-icon-right mb-2">
                      <AutoComplete
                        value={searchQuery}
                        suggestions={searchResults}
                        completeMethod={searchLocation}
                        onSelect={onLocationSelect}
                        onChange={(e) => setSearchQuery(e.value)}
                        field="name"
                        placeholder="Search location..."
                        className=""
                      />
                      <i className="pi pi-search" />
                    </span>
                    <Button
                      icon="pi pi-map-marker"
                      className="p-button-rounded p-button-secondary ml-2"
                      onClick={getUserLocation}
                      loading={isGettingLocation}
                      tooltip="Use my location"
                    />
                  </div>
                  <div
                    className="map-container"
                    ref={mapContainer}
                    style={{
                      width: "300px",
                      height: "200px",
                      marginBottom: "1rem",
                    }}
                  />
                </div>
              </div>
            </div>

            {/* Section 2: Period */}
            <div className="flex">
              <div className="flex flex-col items-center mr-4">
                <div>
                  <div className="flex items-center justify-center w-10 h-10 border rounded-full rounded-border-contnet">
                    2
                  </div>
                </div>
                <div className="w-px h-full bg-gray-500 border-steps" />
              </div>
              <div className="pt-1 pb-8">
                <p className="mb-2 text-lg font-bold select-steps-contnet">
                  Period
                </p>
                <div className="period-inputs">
                  <Calendar
                    value={startDate}
                    onChange={handleStartDateChange}
                    inline
                    className="mr-2 calendar-custom"
                    panelClassName="calendar-panel"
                    showTime
                    hourFormat="12"
                    minDate={
                      new Date(
                        new Date().setFullYear(new Date().getFullYear() - 1)
                      )
                    }
                    maxDate={endDate}
                    tooltip="Select a start date (up to 1 year ago)"
                  />
                  <Calendar
                    value={endDate}
                    onChange={handleEndDateChange}
                    inline
                    panelClassName="calendar-panel"
                    showTime
                    hourFormat="12"
                    minDate={startDate}
                    maxDate={new Date()}
                    tooltip="Select an end date (must be after start date)"
                  />
                </div>
              </div>
            </div>

            {/* Section 3: Template */}
            <div className="flex">
              <div className="flex flex-col items-center mr-4">
                <div>
                  <div className="flex items-center justify-center w-10 h-10 border rounded-full rounded-border-contnet">
                    3
                  </div>
                </div>
                <div className="w-px h-full bg-gray-500 border-steps" />
              </div>
              <div className="pt-1 pb-8">
                <p className="mb-2 text-lg font-bold select-steps-contnet">
                  Template
                </p>
                <div className="data-set-container">
                  <span className="p-input-icon-right w-full">
                    <AutoComplete
                      value={dataSet}
                      suggestions={dataSetOptions}
                      completeMethod={(e) => {
                        const filtered = dataSetOptions.filter((option) =>
                          option.label
                            .toLowerCase()
                            .includes(e.query.toLowerCase())
                        );
                        setDataSetSearchResults(filtered);
                      }}
                      field="label"
                      onChange={(e) => setDataSet(e.value)}
                      placeholder="Input Data Set"
                      className="w-100"
                    />
                    <i className="pi pi-search" />
                  </span>
                  <ListBox
                    options={dataSetOptions}
                    className="data-set-list mt-2"
                    value={selectedDataSet}
                    onChange={(e) => setSelectedDataSet(e.value)}
                  />
                </div>
              </div>
            </div>

            {/* Section 4: Process */}
            <div className="flex">
              <div className="flex flex-col items-center mr-4">
                <div>
                  <div className="flex items-center justify-center w-10 h-10 border rounded-full rounded-border-contnet">
                    4
                  </div>
                </div>
              </div>
              <div className="pt-1 pb-8">
                <p className="mb-2 text-lg font-bold select-steps-contnet justify-center align-center">
                  Process
                </p>
                <div className="analysis-options mb-6">
                  <p className="mb-2">Analysis fidelity</p>
                  <div className="card flex">
                    <div className="flex flex-wrap gap-3">
                      <div className="flex align-items-center">
                        <RadioButton
                          inputId="analysis_low"
                          name="analysisLevel"
                          value="low"
                          onChange={(e) => setAnalysisLevel(e.value)}
                          checked={analysisLevel === "low"}
                        />
                        <label
                          htmlFor="analysis_low"
                          className="ml-2 cursor-pointer"
                        >
                          Low
                        </label>
                      </div>
                      <div className="flex align-items-center">
                        <RadioButton
                          inputId="analysis_medium"
                          name="analysisLevel"
                          value="medium"
                          onChange={(e) => setAnalysisLevel(e.value)}
                          checked={analysisLevel === "medium"}
                        />
                        <label
                          htmlFor="analysis_medium"
                          className="ml-2 cursor-pointer"
                        >
                          Medium
                        </label>
                      </div>
                      <div className="flex align-items-center">
                        <RadioButton
                          inputId="analysis_high"
                          name="analysisLevel"
                          value="high"
                          onChange={(e) => setAnalysisLevel(e.value)}
                          checked={analysisLevel === "high"}
                        />
                        <label
                          htmlFor="analysis_high"
                          className="ml-2 cursor-pointer"
                        >
                          High
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <Button
                  label={isLoading ? "Processing..." : "Process"}
                  className="submitbtn justify-center align-items-center"
                  loading={isLoading}
                  onClick={handleProcess}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DataTrendPage;
