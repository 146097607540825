import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { BACKEND_URL } from "../../environment";
import { useSelector } from "react-redux";

import ReactPlayer from "react-player";

import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css"; // Official Mapbox Geocoder CSS
import "mapbox-gl/dist/mapbox-gl.css";

mapboxgl.accessToken =
  "pk.eyJ1IjoiZ2VvdGhhcmEiLCJhIjoiY2xrd21tb2VpMTJ0djNybXliZzhwb29hOCJ9.3hVMlLmgoVu8cOPEH5IsYg";

const VideoPage = () => {
  const mapContainer = useRef(null);
  const map = useRef(null);

  const { userData } = useSelector((state) => state.authReducer);

  const [inputText, setInputText] = useState("");
  const [videoSource, setVideoSource] = useState(""); // Default video source
  const [showMap, setShowMap] = useState(true);
  const [showVideo, setShowVideo] = useState(false);
  const [time, setTime] = useState("00:00:00");
  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };
  const handleInputChange = (event) => {
    const input = event.target.value;
    const numericInput = input.replace(/\D/g, "");

    let formattedTime = "";

    for (let i = 0; i < Math.min(6, numericInput.length); i++) {
      if (i === 2 || i === 4) {
        formattedTime += ":";
      }
      formattedTime += numericInput[i];
    }

    setTime(formattedTime);
  };

  const handleInputBlur = () => {
    setIsEditing(false);
  };

  // useEffect(() => {
  //   scrollToBottom();
  // }, []);

  useEffect(() => {
    scrollToBottom();
  }, [showMap, showVideo]);

  function scrollToBottom() {
    var container = document.getElementById("scrollContainer");
    container.scrollTop = container.scrollHeight;
  }

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${userData.token}`,
    },
  };

  function getFileExtension(filename) {
    const parts = filename.split(".");
    if (parts.length > 1) {
      return parts[parts.length - 1];
    }
    return null;
  }

  const fileExtension = getFileExtension(videoSource);

  const storedWords = JSON.parse(localStorage.getItem("inputWords"));

  function handleInputKeyDown(event) {
    if (event.key === "Enter") {
      handleSendClick();
      // handleShowLocation();
    }
  }
  const [apiResponse, setApiResponse] = useState(null); // State to store API response

  useEffect(() => {
    if (apiResponse && apiResponse.video) {
      setVideoSource(`/img/${apiResponse.video}`);
    }
  }, [apiResponse]);

  const handleInputText = (e) => {
    setInputText(e.target.value);
  };

  function handleSendClick() {
    const formData = new FormData();
    formData.append("videoInput", inputText);

    setShowMap(true); // Show the map initially
    setShowVideo(false); // Hide the video initially

    const apiUrl = `${BACKEND_URL}video_filename/`;

    axios
      .post(apiUrl, formData, config)
      .then((response) => {
        if (response.data.video) {
          handleShowLocation();
          const storedWords =
            JSON.parse(localStorage.getItem("inputWords")) || [];

          storedWords.push(inputText);

          localStorage.setItem("inputWords", JSON.stringify(storedWords));
        }
        setTimeout(() => {
          scrollToBottom();
        }, 200);
        // scrollToBottom();

        // setShowMap(true);
        // setTimeout(() => {
        //   setShowMap(false);
        // }, 8000);
        // Set timeouts to control the transitions
        if (!showMap) {
          setShowMap(true);
        }

        setTimeout(() => {
          setShowMap(false);

          setTimeout(() => {
            setApiResponse(response.data);
            setShowVideo(true); // Show the video after the map transition
          }, 2000); // Adjust the timing based on your transition duration
        }, 8000);
      })
      .catch((error) => {
        setShowMap(true);
      });
  }

  useEffect(() => {
    if (map.current) return;

    const newMap = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/light-v10",
      center: [-74.006, 40.7128],
      zoom: 12,
      showControls: false,
    });

    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      marker: false,
      position: "top-left",
    });
    newMap.addControl(geocoder, "top-right");

    map.current = newMap;
  }, []);

  const handleShowLocation = () => {
    const lowerCaseInput = inputText.toLowerCase();

    if (
      lowerCaseInput === "water fall" ||
      lowerCaseInput === "pumped hydro" ||
      lowerCaseInput === "horton"
    ) {
      map.current.flyTo({ center: [-73.906333, 42.659195], zoom: 17 });
      return;
    }

    if (
      lowerCaseInput.includes("nyc") ||
      lowerCaseInput.includes("cso") ||
      lowerCaseInput.includes("pipe")
    ) {
      map.current.flyTo({ center: [-74.006, 40.7128], zoom: 12 }); // New York City coordinates
      return;
    }

    if (
      lowerCaseInput.includes("atmospheric river") ||
      lowerCaseInput.includes("ferc") ||
      lowerCaseInput.includes("relicensing") ||
      lowerCaseInput.includes("skagit")
    ) {
      map.current.flyTo({ center: [-122.3321, 47.6062], zoom: 12 }); // Seattle coordinates
      return;
    }

    axios
      .get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${inputText}.json`,
        {
          params: {
            access_token: mapboxgl.accessToken,
          },
        }
      )
      .then((response) => {
        const [longitude, latitude] = response.data.features[0].center;

        // Update the map's center to the chosen location
        map.current.flyTo({ center: [longitude, latitude], zoom: 10 });
      })
      .catch((error) => {
        console.error("Error fetching location coordinates:", error);
      });
  };

  return (
    <div>
      <div className="bg-[#cdcdcd63] pb-4 h-screen main-geofont-fam">
        <div className="other-graph-contnet ">
          <div className="main-vediopage ">
            <div className="vediopage md:my-4 md:mx-4">
              <div className="main-mapconatiner">
                <div
                  className="mapbox-show"
                  ref={mapContainer}
                  style={{
                    opacity: showMap ? 1 : 0,
                    display: "block",
                    width: "100%",
                    height: "750px",
                    position: "relative",
                  }}
                />
              </div>

              <div
                className="player-container"
                style={{ opacity: showVideo ? 1 : 0 }}
              >
                {fileExtension !== "gif" ? (
                  <ReactPlayer
                    className="map-vedioshow"
                    url={videoSource ? videoSource : "/img/Demo_22seconds.mov"}
                    controls={true}
                    playing={showVideo && true}
                    loop={true}
                    width="100%"
                    height="auto"
                  />
                ) : (
                  <img className="vedioshow-gif" src={videoSource} alt="GIF" />
                )}
              </div>
            </div>

            {/* chat  */}
            <div className="ai-contnettype">
              <div className="count-down">
                <h1 className="expoort-heading">Length</h1>
                <div className="timer-countai">
                  {isEditing ? (
                    <input
                      type="text"
                      className="edit-timervideo"
                      value={time}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      autoFocus
                    />
                  ) : (
                    <h1 className="count-downtime" onClick={handleEditClick}>
                      {time ? time : "00:00:00"}
                    </h1>
                  )}
                </div>
              </div>

              <div class={"flex-1 reschatpastRight"}>
                <div class="headerai h-20 bg-[#292727] flex items-center gap-4 ">
                  {" "}
                  <span class=" ml-3 h-12 w-12 flex items-center ">
                    <div class="relative">
                      <i class="fas fa-robot robotai"></i>
                      <span class="top-0 left-[42px] absolute  w-3 h-3 bg-green-400  rounded-full"></span>
                    </div>
                  </span>
                  <p class="text-center text-white text-3xl ">AI Assistant</p>
                </div>
                <div
                  class="flex flex-col gap-2 bg-[#141414] chatpast-sectionlist"
                  id="scrollContainer"
                >
                  <div class="flex gap-3 px-3 py-5">
                    <div class="overflow-hidden text-left">
                      <div class=" flex flex-col gap-2">
                        {storedWords?.map((item) => (
                          <div class="mt-4 px-4 flex items-center gap-3">
                            <>
                              <h1 class="px-2 h-10 justify-center items-center text-sm rounded-tr-xl	rounded-tl-xl rounded-br-xl	w-90 flex bg-white">
                                {item}
                              </h1>
                            </>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex items-center justify-between w-full p-3  bg-[#141414]">
                  <input
                    type="text"
                    value={inputText}
                    onChange={(e) => handleInputText(e)}
                    onKeyDown={handleInputKeyDown}
                    placeholder="How can I help?"
                    class="block w-full py-3 pl-4 mx-3 bg-[#cac8c8] rounded-xl outline-none focus:text-gray-700 placeholder:text-gray-700 placeholder:text-xl"
                    name="message"
                  />

                  <button type="submit" onClick={handleSendClick}>
                    <svg
                      class="sendmessagevedio w-5 h-5 text-gray-500 origin-center transform rotate-90"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                    </svg>
                  </button>
                </div>
              </div>

              <div className="socialmedia-icon">
                <div className="icons-socialmedia">
                  <h1 className="expoort-heading">Export</h1>
                  <div class="socialmediagridicon ">
                    <img
                      className="social-media-iconss"
                      src="/img/insatgram-removebg-preview.png"
                    />
                    <img
                      className="social-media-iconss"
                      src="/img/YouTube-removebg-preview.png"
                    />
                    <img
                      className="social-media-iconss"
                      src="/img/tiktok-removebg-preview.png"
                    />
                    <img
                      className="social-media-iconss rounded-lg"
                      src="/img/twitter.png"
                    />

                    <i class="fa fa-download downloadicon"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoPage;
