import {
  NEW_TEXTFILE_REQUEST,
  NEW_TEXTFILE_SUCCESS,
  NEW_TEXTFILE_FAIL,
  PERPLEXITY_AI_REQUEST,
  PERPLEXITY_AI_SUCCESS,
  PERPLEXITY_AI_FAIL,
  SAVENEWFILE_REQUEST,
  SAVENEWFILE_SUCCESS,
  SAVENEWFILE_FAIL,
  GET_TEXTFILES_REQUEST,
  GET_TEXTFILES_SUCCESS,
  GET_TEXTFILES_FAIL,
  DELETE_TEXTFILES_REQUEST,
  DELETE_TEXTFILES_SUCCESS,
  DELETE_TEXTFILES_FAIL,
  NEW_TEXTFILE_RESET,
  SAVENEWFILE_RESET,
  GET_TEXTFILES_RESET,
  POST_TRACKING_ACTION_REQUEST,
  POST_TRACKING_ACTION_SUCCESS,
  POST_TRACKING_ACTION_FAIL,
  POST_TRACKING_ACTION_RESET,
  GET_PERMISSIONS_REQUEST,
  GET_PERMISSIONS_SUCCESS,
  GET_PERMISSIONS_FAIL,
  GET_PERMISSIONS_RESET,
} from "../Constants/newfile-constant";

export const newTextFileReducer = (state = {}, action) => {
  switch (action.type) {
    case NEW_TEXTFILE_REQUEST:
      return { loading: true };

    case NEW_TEXTFILE_SUCCESS:
      return {
        loading: false,
        success: true,
        addfiledata: action.payload,
        message: action.payload.message,
      };

    case NEW_TEXTFILE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case NEW_TEXTFILE_RESET:
      return {};

    default:
      return state;
  }
};


export const getPermissionsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PERMISSIONS_REQUEST:
      return { loading: true };

    case GET_PERMISSIONS_SUCCESS:
      return {
        loading: false,
        success: true,
        getPermission: action.payload,
        message: action.payload.message,
      };

    case GET_PERMISSIONS_FAIL:
      return { loading: false, error: action.payload };

    case GET_PERMISSIONS_RESET:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const perplexityAIReducer = (state = {}, action) => {
  switch (action.type) {
    case PERPLEXITY_AI_REQUEST:
      return { loading: true };

    case PERPLEXITY_AI_SUCCESS:
      return {
        loading: false,
        success: true,
        perplexitydata: action.payload,
        message: action.payload.message,
      };

    case PERPLEXITY_AI_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const saveNewFileReducer = (state = {}, action) => {
  switch (action.type) {
    case SAVENEWFILE_REQUEST:
      return { loading: true };

    case SAVENEWFILE_SUCCESS:
      return {
        loading: false,
        success: true,
        saveFileData: action.payload,
        message: action.payload.message,
      };

    case SAVENEWFILE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case SAVENEWFILE_RESET:
      return {};

    default:
      return state;
  }
};

export const getTextFileReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_TEXTFILES_REQUEST:
      return { loading: true };

    case GET_TEXTFILES_SUCCESS:
      return {
        loading: false,
        success: true,
        getNewFiles: action.payload,
        message: action.payload.message,
      };

    case GET_TEXTFILES_FAIL:
      return { loading: false, error: action.payload };

    case GET_TEXTFILES_RESET:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const deleteTextFileReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_TEXTFILES_REQUEST:
      return { loading: true };

    case DELETE_TEXTFILES_SUCCESS:
      return {
        loading: false,
        success: true,
        message: action.payload.message,
      };

    case DELETE_TEXTFILES_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};


export const posttrackingaction = (state = {}, action) => {
  switch (action.type) {
    case POST_TRACKING_ACTION_REQUEST:
      return { loading: true };

    case POST_TRACKING_ACTION_SUCCESS:
      return {
        loading: false,
        success: true,
        saveFileData: action.payload,
        message: action.payload.message,
      };

    case POST_TRACKING_ACTION_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case POST_TRACKING_ACTION_RESET:
      return {};

    default:
      return state;
  }
};