import React, { useState, useEffect, useRef } from "react";
import html2canvas from "html2canvas";
import { Line } from "recharts";
import { useSelector, useDispatch } from "react-redux";
import { PieChart, Pie, Cell } from "recharts";
import {
  perplexityAiAction,
  postPermissionsAction,
} from "../../Redux/Action/newfile-action";
import {
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  Label,
} from "recharts";

const FileGraphPage = (props) => {
  const dispatch = useDispatch();
  const { addfiledata, selectedMethod, successAdd } = props;

  const [articles, setArticles] = useState("");
  const [diagno, setDiagno] = useState(false);
  const [histogramPlot, setHistogramPlot] = useState("");
  const [clickfile, setClickfile] = useState(0);

  const domRef = useRef();
  const domRef2 = useRef();
  const domRef3 = useRef();

  const [linearPiecewiseData, setLinearPiecewiseData] = useState();
  const [stepwiseData, setStepwiseData] = useState();
  const [permission, setPermission] = useState(false);

  const [clickedName] = useState("piecewise_linear");
  const { getPermission } = useSelector((state) => state.getPermissionsReducer);

  const { perplexitydata, loading: loadingAi } = useSelector(
    (state) => state.perplexityAIReducer
  );

  useEffect(() => {
    if (getPermission) {
      setPermission(getPermission?.is_track);
    }
  }, [getPermission]);

  useEffect(() => {
    if (perplexitydata) {
      setArticles(perplexitydata);
    }
  }, [perplexitydata]);

  useEffect(() => {
    if (addfiledata) {
      if (selectedMethod === "Hamed Rao Test") {
        setHistogramPlot(addfiledata?.hist_data);
        localStorage.setItem(
          "response_data",
          JSON.stringify(addfiledata?.clicks?.num_of_click)
        );
        setArticles([]);
      }
      if (selectedMethod === "Yue Wang Test") {
        setHistogramPlot(addfiledata?.hist_data);
        localStorage.setItem(
          "response_data",
          JSON.stringify(addfiledata?.clicks?.num_of_click)
        );
        setArticles([]);
      }
      const linearPiecewise =
        addfiledata?.piecewise_linear?.line_piecewise_values?.map(
          (value, index) => ({
            name: value,
            value:
              addfiledata?.piecewise_linear?.points_data_piecewise?.[index],
            trend:
              addfiledata?.piecewise_linear?.line_piecewise_values?.[index],
          })
        );
      setLinearPiecewiseData(linearPiecewise);
      // setLinearPiecewiseData(addfiledata?.piecewise_linear);
      const stepwise = addfiledata?.step_change?.line_piecewise_values?.map(
        (value, index) => ({
          name: value,
          value: addfiledata?.step_change?.points_data_piecewise?.[index],
          trend: addfiledata?.step_change?.line_piecewise_values?.[index],
        })
      );
      setStepwiseData(stepwise);
    }
  }, [successAdd]);

  // plotting
  const dataConfidence = [
    {
      x: -50,
      y: 0,
    },
    {
      x: -50,
      y: -50,
    },
    {
      x: -0,
      y: -50,
    },
    {
      x: 0,
      y: 0,
    },
    {
      x: 50,
      y: 0,
    },
    {
      x: 50,
      y: 50,
    },
    {
      x: 0,
      y: 50,
    },
  ];

  // download image
  const handleDownload = () => {
    html2canvas(domRef.current).then((canvas) => {
      const image = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.href = image;
      downloadLink.download = "image.png";
      downloadLink.click();
    });
  };

  const handleConfidenceDownload = () => {
    html2canvas(domRef2.current).then((canvas) => {
      const image = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.href = image;
      downloadLink.download = "image.png";
      downloadLink.click();
    });
  };

  const handleHistogramDownload = () => {
    html2canvas(domRef3.current).then((canvas) => {
      const image = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.href = image;
      downloadLink.download = "image.png";
      downloadLink.click();
    });
  };

  const dataofhisto = histogramPlot?.sorted_data?.map((value, index) => ({
    x: value,
    y: index,
  }));

  // ========= NEW LAYOUT ===========

  const [progressPercent, setProgressPercent] = useState(25);

  const handleClick = (event) => {
    const progressBar = event.currentTarget;
    const progressBarHeight = progressBar.offsetHeight;
    const clickPosition =
      event.clientY - progressBar.getBoundingClientRect().top;
    const newProgressPercent = 100 - (clickPosition / progressBarHeight) * 100;

    setProgressPercent(newProgressPercent);
  };

  const progressStyle = {
    bottom: 0,
    height: `${progressPercent}%`,
    transition: "height 0.3s ease-in-out",
  };

  // round

  const [nominalProgress, setNominalProgress] = useState(80);

  // TODO: Progress
  const [inPracticeProgress, setInPracticeProgress] = useState(60);

  const [valuelinechart, setValue] = useState(0.75);

  const handleChange = (event) => {
    setValue(parseFloat(event.target.value));
  };
  const data01 = [
    { name: "Group A", value: Math.abs(nominalProgress - 100) },
    { name: "Group B", value: nominalProgress },
  ];

  const data02 = [
    { name: "Group A", value: Math.abs(inPracticeProgress - 100) },
    { name: "Group B", value: inPracticeProgress },
  ];

  const COLORS = ["#e5dbdbad", "#7867C1"];

  const [divPosition, setDivPosition] = useState({ top: 0 });
  const [clickedCounts, setClickedCounts] = useState({
    linear_trend: 0,
    piecewise_trend: 0,
    step_change: 0,
    trend_against_slope: 0,
    differencing_operation: 0,
  });

  const handleTextClick = (event) => {
    const textContent = event.target.textContent;
    let clickedName = null;

    switch (textContent) {
      case "Linear trend":
        clickedName = "linear_trend";
        break;
      case "Piecewise trend":
        clickedName = "piecewise_trend";
        break;
      case "Step change":
        clickedName = "step_change";
        break;
      case "Trend against slope":
        clickedName = "trend_against_slope";
        break;
      case "Differencing operation":
        clickedName = "differencing_operation";
        break;
      default:
        break;
    }

    if (clickedName) {
      setClickedCounts((prevCounts) => ({
        ...prevCounts,
        [clickedName]: prevCounts[clickedName] + 1,
      }));
    }

    const textPosition = event.target.getBoundingClientRect();
    const parentPosition = event.target.parentNode.getBoundingClientRect();
    const topPosition = textPosition.top - parentPosition.top;
    setDivPosition({ top: topPosition });
  };
  const handleClickDot = (props) => {
    const formData = new FormData();
    formData.append("flood_magnitude", props?.payload?.trend);
    dispatch(perplexityAiAction(formData));
  };

  const CustomizedDot = (props) => {
    const { cx, cy, payload } = props;

    return (
      <svg
        onClick={() => (permission ? handleClickDot(props) : null)}
        x={cx - 5}
        y={cy - 5}
        width={20}
        height={20}
        fill="  #7867c1"
        viewBox="0 0 1024 1024"
      >
        <path d="M512 0L185.6 512 512 1024l326.4-512L512 0z"></path>
      </svg>
    );
  };

  const trackClick2 = (e) => {
    e.preventDefault();
    setClickfile((prevClickfile) => prevClickfile + 1);

    // Get existing data from local storage or initialize an empty object
    const existingData = JSON.parse(localStorage.getItem("analysis")) || {
      analysis: {},
    };

    // Update the existing object with the new data
    existingData.analysis = {
      analysis_page_click: clickfile + 1, // Incremented overall click count
      clickedCounts: clickedCounts, // Object containing counts for each type of trend
    };

    // Store the updated data back into local storage
    localStorage.setItem("analysis", JSON.stringify(existingData));
  };

  useEffect(() => {
    if (loadingAi) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [loadingAi]);

  const handleToggle = () => {
    setPermission(!permission);
    const formData2 = new FormData();
    formData2.append("is_track", permission ? "False" : "True");
    dispatch(postPermissionsAction(formData2));
  };

  const handleToggleDiagno = () => {
    setDiagno(!diagno);
  };

  return (
    <>
      <div
        className="bg-[#cdcdcd63]  pb-4 h-screen"
        onClick={(e) => trackClick2(e)}
      >
        <div className="other-graph-contnet">
          <div className="otherdiv-main-divv">
            <div className="responsive-graphd-first">
              <ResponsiveContainer width="90%" height={550}>
                <LineChart
                  data={
                    clickedName === "step_change"
                      ? stepwiseData
                      : linearPiecewiseData
                  }
                >
                  <CartesianGrid />
                  <XAxis dataKey="name">
                    <Label
                      value="Time"
                      offset={0}
                      dy={15}
                      position="insideBottom"
                      style={{ fontSize: "25px", margin: "10px" }}
                    />
                  </XAxis>
                  <YAxis>
                    <Label
                      value="Data"
                      angle={-90}
                      position="insideLeft"
                      style={{ fontSize: "25px" }}
                    />
                  </YAxis>
                  <Tooltip />
                  <Legend />
                  <Line
                    type="none"
                    dataKey="value"
                    stroke="#7867c1"
                    fill="#7867c1"
                    dot={<CustomizedDot />}
                    opacity={0}
                  />
                  <Line
                    strokeWidth={2}
                    type="monotone"
                    dataKey="trend"
                    dot={null}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
            <div className="responsivepiechart-div">
              <div className="responsive-graph-second">
                <div className="new-toggle-div flex gap-[10px]">
                  <label class="flex items-center relative w-max cursor-pointer select-none">
                    <span class="diagnostic mr-3">Diagnostic Tools</span>
                    <input
                      type="checkbox"
                      onClick={() => handleToggleDiagno()}
                      class={`toggle-new-swith appearance-none transition-colors cursor-pointer rounded-lg focus:outline-none ${
                        diagno ? "bg-[#7c65be]" : "bg-gray-500"
                      }`}
                    />
                    <span class="absolute font-medium text-xs uppercase right-1 text-white">
                      {" "}
                      OFF
                    </span>
                    <span class="absolute font-medium text-xs uppercase right-8 text-white">
                      ON
                    </span>
                    <span
                      onClick={() => handleToggleDiagno()}
                      class={`circule-div w-7 h-7 ${
                        diagno ? "right-[0px]" : "right-7"
                      }  absolute rounded-lg transform transition-transform bg-gray-200`}
                    />
                  </label>
                </div>
                <div className="toggle-switch mt-[20px]">
                  <label className="select-toggle-btn">Permissions AI</label>
                  <label class="switch">
                    <input
                      checked={permission}
                      type="checkbox"
                      class="switch-input"
                    />
                    <span
                      onClick={(e) => handleToggle()}
                      class="switch-label"
                      data-on="On"
                      data-off="Off"
                    ></span>
                    <span class="switch-handle"></span>
                  </label>
                </div>
                {/* FIXME: */}

                <div className="progressbar-div">
                  <div className="progress-wrap" onClick={handleClick}>
                    <div
                      className="inner-div"
                      style={{
                        transform: `translateY(${divPosition.top}px)`,
                      }}
                    ></div>
                  </div>
                  <div className="liner-trned">
                    <div
                      onClick={handleTextClick}
                      className="lner-progress-trend"
                    >
                      Linear trend
                    </div>
                    <div
                      onClick={handleTextClick}
                      className="lner-progress-trend"
                    >
                      Piecewise trend
                    </div>
                    <div
                      onClick={handleTextClick}
                      className="lner-progress-trend"
                    >
                      Step change
                    </div>
                    <div
                      onClick={handleTextClick}
                      className="lner-progress-trend"
                    >
                      Trend against slope
                    </div>
                    <div
                      onClick={handleTextClick}
                      className="lner-progress-trend"
                    >
                      Differencing operation
                    </div>
                  </div>
                </div>
                <div className="progressbar-div11 mt-6">
                  <span class="diagnostic mr-3 ">Confidence Values</span>
                  <div className="piechartdtata flex gap-8 mt-4">
                    <div>
                      <div className="cirlce-progress ">
                        <div className="pieChart designdiv flex gap-1">
                          <PieChart width={80} height={80}>
                            <Pie
                              data={data01}
                              cx="50%"
                              cy="50%"
                              labelLine={false}
                              outerRadius={35}
                              fill="#8884d8"
                              dataKey="value"
                            >
                              {data01?.map((entry, index) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={COLORS[index % COLORS.length]}
                                />
                              ))}
                            </Pie>
                          </PieChart>
                          <div className="prog-number">
                            {nominalProgress}
                            <span className="percantage-cion">%</span>
                          </div>
                        </div>
                        <div className="lner-progress-trend stpepercan text-center">
                          Nominal
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="cirlce-progress ">
                        <div className="pieChart designdiv flex gap-1">
                          <PieChart width={80} height={80}>
                            <Pie
                              data={data02}
                              cx="50%"
                              cy="50%"
                              labelLine={false}
                              outerRadius={35}
                              fill="#8884d8"
                              dataKey="value"
                            >
                              {data02?.map((entry, index) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={COLORS[index % COLORS.length]}
                                />
                              ))}
                            </Pie>
                          </PieChart>
                          <div className="prog-number">
                            {inPracticeProgress}
                            <span className="percantage-cion">%</span>
                          </div>
                        </div>
                        <div className="lner-progress-trend stpepercan text-center">
                          In practice
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* second div  */}

                <div className="progressbar-div11 mt-6">
                  <span class="diagnostic mr-3 mt-6 ">
                    Aggregated correlation strength
                  </span>
                  <div className="trade-showingdiv flex gap-8 mt-4">
                    <div className="first-valuelinechart">
                      <div className="numariclinechart">
                        <div className="numeric-sliderrange second-div">
                          <h2 className="value-cahrtdiv">{valuelinechart}</h2>
                        </div>
                      </div>
                    </div>
                    <div className="second-valuelinechart">
                      <div className="numariclinechart">
                        <div class="first numer-border"></div>
                        <div class="second numer-border"></div>
                        <div class="third numer-border"></div>

                        <div className="numeric-sliderrange ">
                          <fieldset className="slider-container">
                            <input
                              type="range"
                              id="input-slider"
                              className="input-slider"
                              min="0.1"
                              max="1"
                              step="0.1"
                              value={valuelinechart}
                              // onChange={handleChange}
                            />
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="progressbar-div11 mt-6">
                  <span class="diagnostic-trend mr-3 mt-6 ">
                    Trend detected
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {loadingAi ? (
            <div className="loading-container">
              <div className="loading-spinner"></div>
              <p>Loading...</p>
            </div>
          ) : (
            articles &&
            typeof articles === "string" && (
              <div className="tablepreper">
                <table className="custom-table">
                  <thead>
                    <tr>
                      <th>Article</th>
                      <th>Description</th>
                      <th>News Outlet</th>
                      <th>Reference</th>
                    </tr>
                  </thead>
                  <tbody>
                    {articles
                      .split("\n")
                      .filter((line) => line.includes("|"))
                      .slice(2)
                      .map((row, index) => {
                        const columns = row
                          .split("|")
                          .filter((col) => col.trim());

                        if (columns.length >= 4) {
                          const url = columns[3].trim().replace(/\s+/g, "");
                          return (
                            <tr key={index}>
                              <td>{columns[0].trim()}</td>
                              <td>{columns[1].trim()}</td>
                              <td>{columns[2].trim()}</td>
                              <td>
                                <a
                                  href={url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    window.open(
                                      url,
                                      "_blank",
                                      "noopener,noreferrer"
                                    );
                                  }}
                                >
                                  Read more
                                </a>
                              </td>
                            </tr>
                          );
                        }
                        return null;
                      })}
                  </tbody>
                </table>
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default FileGraphPage;
