import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { resetPassword } from "../Redux/Action/authentication-action";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { validations } from "../utils";

const UpdatePassword = () => {
  const { success, error } = useSelector((state) => state.resetPasswordReducer);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userData } = useSelector((state) => state.authReducer);
  const formData = new FormData();

  const [showPassword, setShowPassword] = useState(false);

  const [oldpassword, setoldpassword] = useState("");
  const [newpassword, setnewpassword] = useState("");
  const [cnewpassword, setCnewpassword] = useState("");
  const [rerender, setRerender] = useState(false);

  const [errors, setErrors] = useState({
    oldpassword: null,
    newpassword: null,
    cnewpassword: null,
  });

  const validateSubmit = (e) => {
    e.preventDefault();
    const tempErrors = {
      oldpassword: validations.password(oldpassword),
      newpassword: validations.password(newpassword),
      cnewpassword: validations.confirmPassword(newpassword, cnewpassword),
    };
    setErrors(tempErrors);
    if (Object.values(tempErrors).filter((value) => value).length) {
      return;
    }
    SubmitHandler();
  };

  const SubmitHandler = () => {
    formData.append("current_password", oldpassword);
    formData.append("new_password", newpassword);
    formData.append("confirm_password", cnewpassword);

    dispatch(resetPassword(formData));
  };

  useEffect(() => {
    if (success) {
      swal({
        title: " ",
        text: "Password Reset Successfully",
        className: "successAlert",
        icon: "/Colored icon.png",
        buttons: false,
        timer: 5000,
      });
      setRerender(false);
      navigate("/");
    }
    if (error) {
      swal({
        title: "Error",
        text: error,
        className: "errorAlert",
        icon: "/Colored icon.png",
        buttons: false,
        timer: 5000,
      });
      setRerender(false);
    }
  }, [success, error]);

  return (
    <>
      <div className="Auth-main-div">
        <section
          className="vh-100 gradient-form"
          style={{ backgroundColor: "#42c1f4" }}
        >
          <div className="container h-100 Auth-size">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="logindiv rounded-3 text-black">
                <form onSubmit={validateSubmit} className="loginpgae">
                  <div className="cms-logo">
                    <img className="cms-login-logo" src="/img/logo.png" />
                    <h2 className="titlewelcome"> Update your password</h2>
                  </div>
                  <div
                    className={
                      errors.newpassword
                        ? "inputCntnr inputCntnr2 error"
                        : "inputCntnr inputCntnr2"
                    }
                  >
                    <div className="form-outline mb-2">
                      <label className="form-label" for="current-password">
                        Old password:
                      </label>
                      <input
                        id="current-password"
                        className="form-control"
                        type={showPassword ? "text" : "password"}
                        onChange={(e) => {
                          setoldpassword(e.target.value);
                          setErrors({ ...errors, oldpassword: null });
                        }}
                      />
                      <span
                        style={{
                          color: "#D14F4F",
                          float: "right",
                          fontSize: "13px",
                          opacity: errors.oldpassword ? 1 : 0,
                        }}
                      >
                        {errors.oldpassword ?? "valid"}
                      </span>
                    </div>
                    {/* <div className="show-hide-password"> */}
                    {/* <button onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? (
                        <>
                          <i className="fas fa-solid fa-eye"></i>
                        </>
                      ) : (
                        <>
                          <i className="fas fa-solid fa-eye-slash"></i>
                        </>
                      )}
                    </button> */}
                    {/* </div> */}
                  </div>
                  <div
                    className={
                      errors.newpassword
                        ? "inputCntnr inputCntnr2 error"
                        : "inputCntnr inputCntnr2"
                    }
                  >
                    <div className="form-outline mb-2">
                      <label className="form-label" for="current-password">
                        New Password:
                      </label>
                      <input
                        id="current-password"
                        className="form-control"
                        type={showPassword ? "text" : "password"}
                        onChange={(e) => {
                          setnewpassword(e.target.value);
                          setErrors({ ...errors, newpassword: null });
                        }}
                      />
                      <span
                        style={{
                          color: "#D14F4F",
                          float: "right",
                          fontSize: "13px",
                          opacity: errors.newpassword ? 1 : 0,
                        }}
                      >
                        {errors.newpassword ?? "valid"}
                      </span>
                    </div>
                    {/* <div className="show-hide-password"> */}
                    {/* <button onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? (
                        <>
                          <i className="fas fa-solid fa-eye"></i>
                        </>
                      ) : (
                        <>
                          <i className="fas fa-solid fa-eye-slash"></i>
                        </>
                      )}
                    </button> */}
                    {/* </div> */}
                  </div>
                  <div
                    className={
                      errors.cnewpassword
                        ? "inputCntnr inputCntnr2 error"
                        : "inputCntnr inputCntnr2"
                    }
                  >
                    <div className="form-outline mb-2">
                      <label className="form-label" for="current-password">
                        Confirm Password:
                      </label>
                      <input
                        type="password"
                        id="current-password"
                        className="form-control"
                        onChange={(e) => {
                          setCnewpassword(e.target.value);
                          setErrors({ ...errors, cnewpassword: null });
                        }}
                      />
                      <span
                        style={{
                          color: "#D14F4F",
                          float: "right",
                          fontSize: "13px",
                          opacity: errors.cnewpassword ? 1 : 0,
                        }}
                      >
                        {errors.cnewpassword ?? "valid"}
                      </span>
                    </div>
                  </div>
                  <div className="pt-1 mt-3 pb-1 text-center">
                    <button
                      onSubmit={validateSubmit}
                      className="Auth-button"
                      type="submit"
                    >
                      Update password
                    </button>
                  </div>
                  <div className="pt-1 text-center">
                    <Link to="/dashboard" className="Auth-button back-btn" type="submit">
                      Back
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default UpdatePassword;
