import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { BACKEND_URL } from "../environment";
import swal from "sweetalert";
import axios from "axios";
import { verfyemailAction } from "../Redux/Action/authentication-action";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";

const VerifyEmail = () => {
  const dispatch = useDispatch();

  const { decodeId } = useParams();

  const [render, setRerender] = useState(true);

  // useEffect(() => {
  //   const config = {
  //     headers: {
  //       "Content-type": "application/json",
  //     },
  //   };

  //   const success = axios
  //     .post(`${BACKEND_URL}verify-email/${decodeId}`, config)
  //     .then((res) => {
  //       if (res.data.message) {
  //         setSuccessChk(true);
  //         swal({
  //           title: "Email successfully verified",
  //           text: res.data.message,
  //           className: "successAlert",
  //           icon: "/smallMN.png",
  //           buttons: false,
  //           timer: 200000,
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       if (err.response.data.message) {
  //         setSuccessChk(false);
  //         swal({
  //           title: "Error",
  //           text: err.response.data.message,
  //           className: "errorAlert",
  //           icon: "/smallMN.png",
  //           buttons: false,
  //           timer: 200000,
  //         });
  //       }
  //     });
  // }, []);

  useEffect(() => {
    dispatch(verfyemailAction(decodeId));
    setRerender(true);
  }, []);

  const { success, error } = useSelector((state) => state.verifyEmailReducer);

  useEffect(() => {
    if (success && render) {
      swal({
        title: " Complete",
        text: success,
        className: "successAlert",
        icon: "/img/Colored icon.png",
        buttons: false,
        timer: 100000,
      });
      setRerender(false);
    }

    if (error && render) {
      swal({
        title: "Error",
        text: error,
        className: "errorAlert",
        icon: "/img/Colored icon.png",
        buttons: false,
        timer: 5000,
      });
      setRerender(false);
    }
  }, [success, error]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>verify email</title>
        <meta
          name="description"
          content="Martial Nexus was created by martial artists for martial artists and combat sport athletes. We understand the passion and dedication that goes into training and competing, and we wanted to create a platform where like-minded individuals could come together to share their knowledge and experiences."
        />
        <meta name="keywords" content="testt contnet" />
        <meta name="author" content="Martial nexus" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" data-react-helmet="true" />
      </Helmet>
      <div class="thankyoupage">
        <div class="login-content-registering">
          <div class="thanktext">
            <div className="cms-logo">
              <img className="logo-thankyou" src="/img/logo.png" />
            </div>
            <p className="text-center text-md font-medium">
              Welcome to Geothara
            </p>
            <p className="text-center text-md font-medium">
              Your email has been confirmed.
            </p>
            <div class="center mt-2 thankyoudiv">
              <Link className="successfullyButton3" to="/">
                Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyEmail;
