import React from "react";
import Header from "./Header";
import { Outlet } from "react-router-dom";

function Layout() {
  return (
    <div className="main_appLayout">
      <div className="Dasboard">
        {/* <div className="left-dasboard">
          <Sidebar />
        </div> */}
        <div className="right-dasboard">
          <div className="main_navbar_header">
            <Header />
          </div>
          <div className="layout-dashboard-main">
            <Outlet />
          </div>
          {/* <div className="main_navbar_header">
            <Footer />
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Layout;
