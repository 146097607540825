import axios from "axios";
import { BACKEND_URL } from "../../environment";

import {
  USER_DATA_REQUEST,
  USER_DATA_SUCCESS,
  USER_DATA_FAIL,
  MEMBERSHIP_REQUEST,
  MEMBERSHIP_SUCCESS,
  MEMBERSHIP_FAIL,
  MEMBERSHIP_DETAILS_REQUEST,
  MEMBERSHIP_DETAILS_SUCCESS,
  MEMBERSHIP_DETAILS_FAIL,
} from "../Constants/payment-constant";

export const PaymentdataAction = (params) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DATA_REQUEST,
    });

    const {
      authReducer: { userData },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userData.token}`,
      },
    };

    const { data } = await axios.post(
      `${BACKEND_URL}checkout_session/`,
      params,
      config
    );

    dispatch({
      type: USER_DATA_SUCCESS,
      payload: data,
    });

    return true;
  } catch (error) {
    dispatch({
      type: USER_DATA_FAIL,
      payload:
        error?.response && error.response.data.message?.email?.[0]
          ? error.response && error.response.data.message?.email?.[0]
          : error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const MembershipData = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: MEMBERSHIP_REQUEST,
    });

    const {
      authReducer: { userData },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userData.token}`,
      },
    };

    const { data } = await axios.get(`${BACKEND_URL}membership/`, config);

    dispatch({
      type: MEMBERSHIP_SUCCESS,
      payload: data,
    });

    return true;
  } catch (error) {
    dispatch({
      type: MEMBERSHIP_FAIL,
      payload:
        error?.response && error.response.data.message?.email?.[0]
          ? error.response && error.response.data.message?.email?.[0]
          : error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const MembershipDetails = (id) => async (dispatch) => {
  try {
    dispatch({
      type: MEMBERSHIP_DETAILS_REQUEST,
    });

    const { data } = await axios.get(`${BACKEND_URL}membership/${id}`);

    dispatch({
      type: MEMBERSHIP_DETAILS_SUCCESS,
      payload: data,
    });

    return true;
  } catch (error) {
    dispatch({
      type: MEMBERSHIP_DETAILS_FAIL,
      payload:
        error?.response && error.response.data.message?.email?.[0]
          ? error.response && error.response.data.message?.email?.[0]
          : error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
