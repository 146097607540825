import {
  USER_DATA_REQUEST,
  USER_DATA_SUCCESS,
  USER_DATA_FAIL,
  MEMBERSHIP_REQUEST,
  MEMBERSHIP_SUCCESS,
  MEMBERSHIP_FAIL,
  MEMBERSHIP_DETAILS_REQUEST,
  MEMBERSHIP_DETAILS_SUCCESS,
  MEMBERSHIP_DETAILS_FAIL,
} from "../Constants/payment-constant";

export const PaymentReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DATA_REQUEST:
      return { loading: true };

    case USER_DATA_SUCCESS:
      return {
        loading: false,
        success: true,
        checkoutResponse: action.payload,
        message: action.payload.message,
      };

    case USER_DATA_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const MembershipReducer = (state = {}, action) => {
  switch (action.type) {
    case MEMBERSHIP_REQUEST:
      return { loading: true };

    case MEMBERSHIP_SUCCESS:
      return {
        loading: false,
        success: true,
        memberData: action.payload,
        message: action.payload.message,
      };

    case MEMBERSHIP_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const MembershipDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case MEMBERSHIP_DETAILS_REQUEST:
      return { loading: true };

    case MEMBERSHIP_DETAILS_SUCCESS:
      return {
        loading: false,
        success: true,
        memberData: action.payload,
        message: action.payload.message,
      };

    case MEMBERSHIP_DETAILS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
