import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { logout } from "../Redux/Action/authentication-action";
import { useDispatch, useSelector } from "react-redux";
import { posttrackingaction } from "../Redux/Action/newfile-action";


function Header() {
  const dispatch = useDispatch();

  const [count, setCount] = useState();
  const [showmenu, setShowmenu] = useState(false);

  const { userData } = useSelector((state) => state.authReducer);

  const { success: successAdd, addfiledata } = useSelector(
    (state) => state.newTextFileReducer
  );

  const { success: successSave, saveFileData } = useSelector(
    (state) => state.saveNewFileReducer
  );

  useEffect(() => {
    setCount(localStorage.getItem("response_data"));
  }, [successAdd, successSave]);

  const logoutHandler = () => {
    const existingData = JSON.parse(localStorage.getItem('datascreen')) || { datapage: [] };
    const existingData2 = JSON.parse(localStorage.getItem('analysis')) || { analysis: {} };
    const existingData3 = JSON.parse(localStorage.getItem('overallclick'));

    const combinedData = { ...existingData, ...existingData2, overallclick: existingData3 };
    const formData = new FormData();
    formData.append(`Json_data`, JSON.stringify(combinedData));
    dispatch(posttrackingaction(formData));
    dispatch(logout());
  };

  // useEffect(() => {
  //   if (successLogout && render) {
  //     navigate("/login");
  //   }
  // }, [successLogout]);
  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      if (now.getHours() === 0 && now.getMinutes() === 0 && now.getSeconds() === 0) {
        // Make API call here
        const existingData = JSON.parse(localStorage.getItem('datascreen')) || { datapage: [] };
        const existingData2 = JSON.parse(localStorage.getItem('analysis')) || { analysis: {} };
        const existingData3 = JSON.parse(localStorage.getItem('overallclick'));
    
        const combinedData = { ...existingData, ...existingData2, overallclick: existingData3 };
        const formData = new FormData();
        formData.append(`Json_data`, JSON.stringify(combinedData));
        dispatch(posttrackingaction(formData));
      }
    };

    const intervalId = setInterval(checkTime, 1000); // Check every second

    // Clear interval on component unmount to prevent memory leaks
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array to run only once on component mount


  return (
    <>
      <div className="HomepageMain d-flex">
        <div className="cms-logo ">
          <img className="logo-dashboard  dash" src="/img/logo.png" />
        </div>
        <p className="count">
          Count Left:
          {addfiledata?.clicks?.num_of_click
            ? addfiledata?.clicks?.num_of_click
            : count}
          {/* Count Left:{count ? count : userData?.num_insert_queries} */}
        </p>
        <div class="center mb-2 thankyoudiv">
          <div>
            <li
              className={
                showmenu ? "user-header-div Activediv" : "user-header-div "
              }
              onClick={() => setShowmenu(!showmenu)}
            >
              <Link>
                <img className="imgPro" src="/img/user.svg" />
              </Link>
              {showmenu && (
                <>
                  <div class="loginsigin">
                    {userData?.user == "admin" && (
                      <li>
                        <Link className="hover-primary " to="/userlist">
                          <i className="fas fa-solid fa-users"></i> Userlist
                        </Link>
                      </li>
                    )}

                    {(count > 0 || userData?.num_insert_queries > 0) && (
                      <li>
                        <Link className="hover-primary " to="/dashboard">
                          <i className="fas fa-solid fa-chart-line"></i> Product
                        </Link>
                      </li>
                    )}

                    <li>
                      <Link to="/subscriptions" className="hover-primary">
                        <i className="fas fa-regular fa-money-check-dollar"></i>{" "}
                        Subscription Pack
                      </Link>
                    </li>
                    <li>
                      <Link to="/password-change" className="hover-primary">
                        <i className="fas fa-solid fa-lock"></i> Change password
                      </Link>
                    </li>
                    <li>
                      {" "}
                      <Link
                        onClick={() => logoutHandler()}
                        className="hover-primary"
                        to="/login"
                      >
                        <i className="fas fa-solid fa-right-from-bracket"></i>{" "}
                        Logout
                      </Link>
                    </li>
                  </div>
                </>
              )}
            </li>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
