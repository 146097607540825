import React, { useState, useEffect } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid } from "recharts";
import { Knob } from "primereact/knob";
import "primereact/resources/themes/lara-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import { format } from "date-fns";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "./Dash.css";
import { Card } from "primereact/card";
import { Chart } from "primereact/chart";

function Dash() {
  const [airQuality, setAirQuality] = useState(33);
  const [timeRange, setTimeRange] = useState("day"); // 'day', 'week', or 'month'
  const [currentTime, setCurrentTime] = useState(new Date());
  const [map, setMap] = useState(null);

  // Update fireData state with more accurate coordinates and polygon shapes
  const [fireData] = useState([
    {
      name: "Border 2 Fire",
      acres: 6500,
      coordinates: [-116.7841, 32.6015], // Centered on the fire location
      color: "#ff4d4d",
      polygonCoordinates: [
        [-116.8241, 32.5815],
        [-116.8241, 32.6215],
        [-116.7441, 32.6215],
        [-116.7441, 32.5815],
        [-116.8241, 32.5815],
      ],
    },
    {
      name: "Hunte Fire",
      acres: 450,
      coordinates: [-116.9241, 32.6215], // West of Border Fire
      color: "#ff8533",
      polygonCoordinates: [
        [-116.9341, 32.6115],
        [-116.9341, 32.6315],
        [-116.9141, 32.6315],
        [-116.9141, 32.6115],
        [-116.9341, 32.6115],
      ],
    },
    {
      name: "Iris Fire",
      acres: 0.5,
      coordinates: [-117.0841, 32.5815], // Near Imperial Beach
      color: "#ffcc00",
      polygonCoordinates: [
        [-117.0861, 32.5805],
        [-117.0861, 32.5825],
        [-117.0821, 32.5825],
        [-117.0821, 32.5805],
        [-117.0861, 32.5805],
      ],
    },
  ]);

  useEffect(() => {
    mapboxgl.accessToken =
      "pk.eyJ1IjoiZ2VvdGhhcmEiLCJhIjoiY2xrd21tb2VpMTJ0djNybXliZzhwb29hOCJ9.3hVMlLmgoVu8cOPEH5IsYg";

    const initializeMap = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/mapbox/streets-v11",
      center: [-116.8241, 32.6015], // Centered on Border Fire area
      zoom: 10,
    });

    initializeMap.on("load", () => {
      fireData.forEach((fire) => {
        // Create custom fire marker element
        const el = document.createElement("div");
        el.className = "fire-marker";
        el.style.backgroundImage =
          'url(\'data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 3C16.5 10 19.5 12.5 19.5 16.5C19.5 20 16.5 22 12 22C7.5 22 4.5 20 4.5 16.5C4.5 12.5 7.5 10 12 3Z" fill="%23FF4444"/><path d="M12 7C14.5 11.5 16 13 16 15.5C16 17.7 14.2 19 12 19C9.8 19 8 17.7 8 15.5C8 13 9.5 11.5 12 7Z" fill="%23FFA726"/><circle cx="12" cy="15" r="2.5" fill="%23FFEB3B"/></svg>\')';
        el.style.width = "32px"; // Made slightly larger
        el.style.height = "32px";

        // Add marker
        new mapboxgl.Marker({
          element: el,
          anchor: "bottom",
        })
          .setLngLat(fire.coordinates)
          .setPopup(
            new mapboxgl.Popup({ offset: 25 }).setHTML(
              `<h3 style="font-weight: bold; margin-bottom: 4px;">${fire.name}</h3>
               <p style="margin: 0;">${fire.acres} acres</p>`
            )
          )
          .addTo(initializeMap);

        // Add affected area polygon
        initializeMap.addSource(`${fire.name}-polygon`, {
          type: "geojson",
          data: {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [fire.polygonCoordinates],
            },
          },
        });

        initializeMap.addLayer({
          id: `${fire.name}-polygon`,
          type: "fill",
          source: `${fire.name}-polygon`,
          layout: {},
          paint: {
            "fill-color": fire.color,
            "fill-opacity": 0.4,
            "fill-outline-color": fire.color,
          },
        });
      });
    });

    setMap(initializeMap);

    return () => initializeMap.remove();
  }, [fireData]);

  // Add timer to update current time every minute
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000); // Update every minute (60000 milliseconds)

    // Cleanup timer on component unmount
    return () => clearInterval(timer);
  }, []);

  // Function to determine color based on air quality value
  const getAirQualityColor = (value) => {
    if (value <= 50) return "#22c55e"; // green-500
    if (value <= 100) return "#eab308"; // yellow-500
    if (value <= 150) return "#f97316"; // orange-500
    if (value <= 200) return "#ef4444"; // red-500
    if (value <= 300) return "#a855f7"; // purple-500
    return "#7f1d1d"; // red-900
  };

  // Function to get status text
  const getAirQualityStatus = (value) => {
    if (value <= 50) return "Good";
    if (value <= 100) return "Moderate";
    if (value <= 150) return "Unhealthy for Sensitive Groups";
    if (value <= 200) return "Unhealthy";
    if (value <= 300) return "Very Unhealthy";
    return "Hazardous";
  };

  // Sample data for different time ranges
  const data = {
    day: [
      { time: "12P", value: 15 },
      { time: "1P", value: 15 },
      { time: "2P", value: 15 },
      { time: "3P", value: 15 },
      { time: "4P", value: 14 },
      { time: "5P", value: 12 },
      { time: "6P", value: 10 },
      { time: "7P", value: 8 },
      { time: "8P", value: 10 },
      { time: "9P", value: 12 },
      { time: "10P", value: 15 },
      { time: "11P", value: 20 },
      { time: "12A", value: 25 },
      { time: "1A", value: 33 },
    ],
    week: [
      { time: "Mon", value: 20 },
      { time: "Tue", value: 25 },
      { time: "Wed", value: 30 },
      { time: "Thu", value: 22 },
      { time: "Fri", value: 28 },
      { time: "Sat", value: 33 },
      { time: "Sun", value: 27 },
    ],
    month: [
      { time: "Week 1", value: 25 },
      { time: "Week 2", value: 30 },
      { time: "Week 3", value: 28 },
      { time: "Week 4", value: 33 },
    ],
  };

  // Function to get date range text
  const getDateRangeText = () => {
    switch (timeRange) {
      case "day":
        return `${format(
          new Date(currentTime.getTime() - 24 * 60 * 60 * 1000),
          "EEE, M/d/yy, h:mm a"
        )} to ${format(currentTime, "EEE, M/d/yy, h:mm a")}`;
      case "week":
        return `${format(
          new Date(currentTime.getTime() - 7 * 24 * 60 * 60 * 1000),
          "M/d/yy"
        )} to ${format(currentTime, "M/d/yy")}`;
      case "month":
        return `${format(
          new Date(currentTime.getTime() - 30 * 24 * 60 * 60 * 1000),
          "M/d/yy"
        )} to ${format(currentTime, "M/d/yy")}`;
      default:
        return "";
    }
  };

  const getWindDirectionChart = () => {
    const data = {
      labels: ["N", "NE", "E", "SE", "S", "SW", "W", "NW"],
      datasets: [
        {
          label: "Low Velocity (0-5 m/s)",
          data: [10, 15, 25, 35, 30, 20, 15, 10],
          backgroundColor: "rgba(173, 216, 230, 0.5)", // light blue
          borderColor: "rgba(173, 216, 230, 0.8)",
          borderWidth: 2,
          fill: true,
        },
        {
          label: "Medium Velocity (5-10 m/s)",
          data: [15, 20, 35, 30, 25, 20, 15, 10],
          backgroundColor: "rgba(255, 228, 181, 0.5)", // light orange/yellow
          borderColor: "rgba(255, 228, 181, 0.8)",
          borderWidth: 2,
          fill: true,
        },
        {
          label: "High Velocity (10+ m/s)",
          data: [20, 25, 30, 25, 20, 15, 20, 15],
          backgroundColor: "rgba(255, 182, 193, 0.5)", // light pink/red
          borderColor: "rgba(255, 182, 193, 0.8)",
          borderWidth: 2,
          fill: true,
        },
      ],
    };

    const options = {
      scales: {
        r: {
          beginAtZero: true,
          min: 0,
          max: 40,
          ticks: {
            stepSize: 10,
            display: false,
          },
          grid: {
            color: "#e5e7eb",
          },
          pointLabels: {
            font: {
              size: 14,
              weight: "bold",
            },
          },
        },
      },
      plugins: {
        legend: {
          display: true,
          position: "bottom",
          align: "start",
          labels: {
            usePointStyle: false,
            boxWidth: 40,
            padding: 10,
            generateLabels: function (chart) {
              const datasets = chart.data.datasets;
              return datasets.map((dataset) => ({
                text: dataset.label,
                fillStyle: dataset.backgroundColor,
                strokeStyle: dataset.borderColor,
                lineWidth: dataset.borderWidth,
                hidden: false,
                index: 0,
              }));
            },
          },
          maxWidth: 200,
          maxHeight: 100,
        },
        tooltip: {
          enabled: false,
        },
      },
      events: [], // Disables all events/interactions
      responsive: true,
      maintainAspectRatio: false,
    };

    return { data, options };
  };

  return (
    <>
      <div className="flex flex-col gap-8 p-4 sm:p-6 bg-gray-100">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div className="bg-white rounded-xl shadow-2xl p-4 sm:p-8">
            <div className="border-b border-gray-200 pb-4 mb-6">
              <h2 className="text-3xl font-bold mb-2">Air Quality *</h2>
              <div className="text-gray-600 text-sm">
                {format(currentTime, "EEE, MMM d, hh:mm a")}
              </div>
            </div>

            <div className="relative flex flex-col items-center">
              <div className="relative w-full max-w-[300px] mx-auto">
                <Knob
                  value={airQuality}
                  onChange={(e) => setAirQuality(e.value)}
                  min={0}
                  max={500}
                  showValue={false}
                  size={Math.min(window.innerWidth * 0.8, 300)}
                  startAngle={180}
                  endAngle={360}
                  valueColor={getAirQualityColor(airQuality)}
                  rangeColor="#e5e7eb"
                  strokeWidth={12} // Reduced from 20 to 12
                  textColor="#ffffff"
                  valueTemplate={null}
                />
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
                  <div className="text-6xl font-bold mb-2">{airQuality}</div>
                  <div
                    className="text-2xl font-semibold px-4 py-1 rounded-full"
                    style={{
                      color: getAirQualityColor(airQuality),
                      backgroundColor: `${getAirQualityColor(airQuality)}15`, // Add slight background
                    }}
                  >
                    {getAirQualityStatus(airQuality)}
                  </div>
                </div>
              </div>
              <div className="text-gray-600 mt-6 text-center text-lg">
                {airQuality <= 50
                  ? "No individuals expected to be affected."
                  : "Some individuals may be affected."}
              </div>
            </div>

            <div className="mt-12 space-y-3 bg-gray-50 p-6 rounded-lg">
              <h3 className="font-semibold text-lg mb-4">Air Quality Index</h3>
              <div className="flex items-center gap-2">
                <span className="w-4 h-4 rounded-full bg-green-500"></span>
                <span className="text-sm">Good (0-50)</span>
              </div>
              <div className="flex items-center gap-2">
                <span className="w-4 h-4 rounded-full bg-yellow-500"></span>
                <span className="text-sm">Moderate (51-100)</span>
              </div>
              <div className="flex items-center gap-2">
                <span className="w-4 h-4 rounded-full bg-orange-500"></span>
                <span className="text-sm">
                  Unhealthy for Sensitive Groups (101-150)
                </span>
              </div>
              <div className="flex items-center gap-2">
                <span className="w-4 h-4 rounded-full bg-red-500"></span>
                <span className="text-sm">Unhealthy (151-200)</span>
              </div>
              <div className="flex items-center gap-2">
                <span className="w-4 h-4 rounded-full bg-purple-500"></span>
                <span className="text-sm">Very Unhealthy (201-300)</span>
              </div>
              <div className="flex items-center gap-2">
                <span className="w-4 h-4 rounded-full bg-red-900"></span>
                <span className="text-sm">Hazardous (301-500)</span>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-xl shadow-2xl p-4 sm:p-8">
            <div className="border-b border-gray-200 pb-4 mb-6">
              <h2 className="text-3xl font-bold mb-2">
                Past{" "}
                {timeRange === "day"
                  ? "24 Hours"
                  : timeRange === "week"
                  ? "7 Days"
                  : "30 Days"}
              </h2>
              <div className="text-gray-600 text-sm">{getDateRangeText()}</div>
            </div>

            <div className="bg-gray-50 p-4 rounded-lg overflow-x-auto">
              <div className="min-w-[300px]">
                <LineChart
                  width={Math.min(600, window.innerWidth - 64)}
                  height={300}
                  data={data[timeRange]}
                  margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
                >
                  <CartesianGrid strokeDasharray="3 3" stroke="#e5e7eb" />
                  <XAxis dataKey="time" stroke="#6b7280" />
                  <YAxis domain={[0, 35]} stroke="#6b7280" />
                  <Line
                    type="monotone"
                    dataKey="value"
                    stroke="#00BCD4"
                    strokeWidth={3}
                    dot={false}
                  />
                </LineChart>
              </div>
            </div>

            <div className="flex gap-3 mt-6">
              <button
                className={`px-6 py-2 rounded-lg font-medium focus:outline-none focus:ring-2 focus:ring-opacity-50 ${
                  timeRange === "day"
                    ? "bg-blue-500 text-white"
                    : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                }`}
                onClick={() => setTimeRange("day")}
              >
                Day
              </button>
              <button
                className={`px-6 py-2 rounded-lg font-medium focus:outline-none focus:ring-2 focus:ring-opacity-50 ${
                  timeRange === "week"
                    ? "bg-blue-500 text-white"
                    : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                }`}
                onClick={() => setTimeRange("week")}
              >
                Week
              </button>
              <button
                className={`px-6 py-2 rounded-lg font-medium focus:outline-none focus:ring-2 focus:ring-opacity-50 ${
                  timeRange === "month"
                    ? "bg-blue-500 text-white"
                    : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                }`}
                onClick={() => setTimeRange("month")}
              >
                Month
              </button>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
          {/* Monitoring Locations Card */}
          <div className="lg:col-span-6">
            <Card className="shadow-2xl">
              <h2 className="text-2xl font-bold mb-4">Monitoring Locations</h2>
              <div
                id="map"
                className="w-full h-[300px] sm:h-[500px] rounded-lg"
              ></div>
            </Card>
          </div>

          {/* Wind Direction Card */}
          <div className="lg:col-span-3">
            <Card className="shadow-2xl">
              <h2 className="text-2xl font-bold mb-4">Wind Direction</h2>
              <div
                className="wind-direction-chart"
                style={{ pointerEvents: "none" }}
              >
                <Chart
                  type="radar"
                  data={getWindDirectionChart().data}
                  options={{
                    ...getWindDirectionChart().options,
                    interaction: {
                      mode: null,
                      intersect: false,
                      enabled: false,
                    },
                    hover: {
                      mode: null,
                      intersect: false,
                      enabled: false,
                    },
                    animation: false,
                  }}
                  className="w-full h-[300px]"
                />
              </div>
            </Card>
          </div>

          {/* Weather Card */}
          <div className="lg:col-span-3">
            <Card className="shadow-2xl">
              <div className="text-center">
                <div className="text-6xl sm:text-8xl font-light mb-2">50°</div>
                <div className="text-lg sm:text-xl mb-8">Mostly Cloudy</div>

                <div className="grid grid-cols-2 gap-4 sm:gap-8 text-left mb-8">
                  <div>
                    <h3 className="text-gray-600 mb-2">WIND</h3>
                    <p className="text-xl">SE 5 mph</p>
                  </div>
                  <div>
                    <h3 className="text-gray-600 mb-2">PRESSURE</h3>
                    <p className="text-xl">29.91 inHg</p>
                  </div>
                  <div>
                    <h3 className="text-gray-600 mb-2">VISIBILITY</h3>
                    <p className="text-xl">10 mi</p>
                  </div>
                  <div>
                    <h3 className="text-gray-600 mb-2">HUMIDITY</h3>
                    <p className="text-xl">72%</p>
                  </div>
                </div>

                <div>
                  <h3 className="text-gray-600 mb-4">EXTENDED FORECAST</h3>
                  <div className="space-y-2">
                    <div className="flex justify-between items-center">
                      <span>Overnight</span>
                      <span>▼ 49°</span>
                    </div>
                    <div className="flex justify-between items-center">
                      <span>Saturday</span>
                      <span>▲ 60°</span>
                    </div>
                    <div className="flex justify-between items-center">
                      <span>Saturday Night</span>
                      <span>▼ 48°</span>
                    </div>
                    <div className="flex justify-between items-center">
                      <span>Sunday</span>
                      <span>▲ 55°</span>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dash;
