import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { forgotPassword } from "../Redux/Action/authentication-action";
import { useSelector, useDispatch } from "react-redux";
import swal from "sweetalert";
import { validations } from "../utils";

function Forgot() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [forgotemail, setForgotemail] = useState("");
  const [rerender, setRerender] = useState(false);
  const [errors, setErrors] = useState({
    forgotemail: null,
  });

  const { message, success, error } = useSelector(
    (state) => state.forgotPasswordReducer
  );

  const formData = new FormData();

  const validateSubmit = (e) => {
    const tempErrors = {
      forgotemail: validations.email(forgotemail),
    };
    setErrors(tempErrors);
    if (Object.values(tempErrors).filter((value) => value).length) {
      return;
    }
    SubmitHandler();
  };

  const SubmitHandler = () => {
    setRerender(true);
    formData.append("email", forgotemail);
    dispatch(forgotPassword(formData));
  };

  useEffect(() => {
    if (success && rerender) {
      swal({
        title: " ",
        text: message,
        className: "successAlert",
        icon: "/img/Colored icon.png",
        buttons: false,
        timer: 5000,
      });
      setRerender(false);
      navigate("/");
    }
    if (error && rerender) {
      swal({
        title: "Error",
        text: error,
        className: "errorAlert",
        icon: "/img/Colored icon.png",
        buttons: false,
        timer: 5000,
      });
      setRerender(false);
    }
  }, [dispatch, success, error]);

  return (
    <>
      <div className="Auth-main-div">
        <section
          className="vh-100 gradient-form"
          style={{ backgroundColor: "#42c1f4", height: "100vh" }}
        >
          <div className="container py-5 h-100 Auth-size">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="forgotpage text-black">
                <form className="loginpgae">
                  <div className="cms-logo">
                    <img className="cms-login-logo" src="img/logo.png" />
                    <h2 className="forgottext">Forgot Password</h2>
                  </div>
                  <div
                    className={
                      errors.forgotemail ? "inputCntnr error" : "inputCntnr"
                    }
                  >
                    <div className="form-outline mb-2">
                      <label className="form-label" htmlFor="form2Example11">
                        Email
                      </label>
                      <input
                        type="email"
                        id="form2Example11"
                        className="form-control"
                        value={forgotemail}
                        onChange={(e) => {
                          setForgotemail(e.target.value);
                          setErrors({ ...errors, forgotemail: null });
                        }}
                      />
                      <span
                        style={{
                          color: "#D14F4F",
                          float: "right",
                          fontSize: "13px",
                          opacity: errors.forgotemail ? 1 : 0,
                        }}
                      >
                        {errors.forgotemail ?? "valid"}
                      </span>
                    </div>
                  </div>
                  <div className="pt-1 pb-1">
                    <button
                      onClick={validateSubmit}
                      className="Auth-button forgotsendbtn"
                      type="button"
                    >
                      Send
                    </button>
                  </div>
                  <div className="d-flex align-items-center justify-content-center pb-4">
                    <Link className="cancel-forgotbtn" to="/login">
                      Cancel
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Forgot;
