import React, { useEffect, useRef } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";

// Authication
import Login from "./Authentication/Login";
import Register from "./Authentication/Register";
import Thankyou from "./Authentication/Thankyou";
import VerifyEmail from "./Authentication/Verify-email";
import Reset_password from "./Authentication/Reset-password";
import UpdatePassword from "./Authentication/Update-password";
import Forgot from "./Authentication/Forgot";

// Payment
import SubscriptionPage from "./dashboard/SubscriptionPage";
import Success from "./dashboard/payment/success";
import Failure from "./dashboard/payment/fail";

// Pages
import PublicDashboard from "./dashboard/publicdashboard";

// Admin Pages
import Userlist from "./Components/userList";

// Routing
import ProtectedRoute from "./routing/protectedRoute";
import AdminRoute from "./routing/adminRoute";
import PublicRoute from "./routing/publicRoute";

// Others
import Error from "./Components/Error";
import Layout from "./layouts/Layout";
import MainTrendPage from "./Components/TrendPages/MainTrendPage";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "./Redux/Action/authentication-action";
import Dash from "./Components/TrendPages/Dash";

function App() {
  const dispatch = useDispatch();

  const { userData } = useSelector((state) => state.authReducer);

  // Use a ref to store the timer value
  const logoutTimerRef = useRef(null);

  const resetLogoutTimer = () => {
    if (!userData) return;
    if (logoutTimerRef.current) {
      clearTimeout(logoutTimerRef.current);
    }

    // Set the timer to log out after 15 minutes (900,000 milliseconds)
    const newLogoutTimer = setTimeout(logoutHandler, 900000);
    logoutTimerRef.current = newLogoutTimer;
  };

  const logoutHandler = () => {
    dispatch(logout());
  };

  useEffect(() => {
    const handleActivity = () => {
      if (userData) {
        // Reset the logout timer on user activity only if the user is authenticated
        resetLogoutTimer();
      }
    };

    const addEventListeners = () => {
      document.addEventListener("mousemove", handleActivity);
      document.addEventListener("keydown", handleActivity);
    };

    const removeEventListeners = () => {
      document.removeEventListener("mousemove", handleActivity);
      document.removeEventListener("keydown", handleActivity);
    };

    // Initial setup of the logout timer only if the user is authenticated
    if (userData) {
      resetLogoutTimer();
      addEventListeners();
    }

    // Clean up event listeners when the component unmounts or if the user logs out
    return () => {
      removeEventListeners();
      if (userData) {
        clearTimeout(logoutTimerRef.current); // Clear the timer if the component unmounts
      }
    };
  }, [userData]);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<PublicDashboard />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/register"
            element={
              <PublicRoute>
                <Register />
              </PublicRoute>
            }
          />
          <Route element={<Layout />}>
            <Route
              path="/userlist"
              element={
                <AdminRoute>
                  <Userlist />
                </AdminRoute>
              }
            />
            <Route
              path="/subscriptions"
              element={
                <ProtectedRoute>
                  <SubscriptionPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <MainTrendPage />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route
            path="/thank-you"
            element={
              <PublicRoute>
                <Thankyou />
              </PublicRoute>
            }
          />
          <Route
            path="/verify-email/:decodeId"
            element={
              <PublicRoute>
                <VerifyEmail />
              </PublicRoute>
            }
          />
          <Route
            path="/forgot"
            element={
              <PublicRoute>
                <Forgot />
              </PublicRoute>
            }
          />
          <Route
            path="/reset-password/:token/:uid"
            element={
              <PublicRoute>
                <Reset_password />
              </PublicRoute>
            }
          />
          <Route
            path="/password-change"
            element={
              <ProtectedRoute>
                <UpdatePassword />
              </ProtectedRoute>
            }
          />

          <Route
            path="/payment-success"
            element={
              <ProtectedRoute>
                <Success />
              </ProtectedRoute>
            }
          />

          <Route
            path="/payment-failure"
            element={
              <ProtectedRoute>
                <Failure />
              </ProtectedRoute>
            }
          />

          <Route path="/dash" element={<Dash />} />

          <Route path="*" element={<Error />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
