import React from "react";
import { useDispatch } from "react-redux";
import {  useNavigate } from "react-router-dom";
import { logout } from "../../Redux/Action/authentication-action";


const Success = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logoutHandler = () => {
    dispatch(logout());
    navigate("/");
  };
  return (
    <>
      <div class="thankyoupage">
        <div class="login-content-registering">
          <div class="thanktext">
            <div className="cms-logo">
              <img className="logo-thankyou" src="/img/logo.png" alt="logo" />
            </div>
            <h3 className="success">Payment Successfull</h3>
            <p>Please Log in to continue</p>
            <div class="center mt-2 thankyoudiv">
            <button className="successLogout" onClick={() => logoutHandler()}>
            Log in
          </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Success;
