import React from "react";

import { Link } from "react-router-dom";

const Failure = () => {
  return (
    <>
      <div class="thankyoupage">
        <div class="login-content-registering">
          <div class="thanktext">
            <div className="cms-logo">
              <img className="logo-thankyou" src="/img/logo.png" alt="logo" />
            </div>
            <h3>Payment Fail</h3>
            <p>Retry</p>
            <div class="center mt-2 thankyoudiv">
              <Link className="successfullyButton" to="/">
                Back to homepage
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Failure;
