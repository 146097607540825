import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import "./dashboard.css";
import {
  MembershipData,
  PaymentdataAction,
} from "../Redux/Action/payment-action";

const SubscriptionPage = () => {
  const dispatch = useDispatch();

  const { userData } = useSelector((state) => state.authReducer);

  useEffect(() => {
    dispatch(MembershipData());
  }, []);

  const {
    memberData,
  } = useSelector((state) => state.MembershipReducer);

  const {
    checkoutResponse,
    success: successCheckout,
  } = useSelector((state) => state.PaymentReducer);

  const handleClick = (data) => {
    dispatch(PaymentdataAction({ data: data, user_id: userData.user_id }));
  };

  useEffect(() => {
    const url = checkoutResponse?.sessionId?.url;
    if (url) {
      window.location.href = url;
    }
  }, [successCheckout]);

  return (
    <>
      <div class="homepage">
        <div class="homepagepay">
          <div class="thanktexthome">
            <p className="Subscription">Choose a Subscription Pack </p>

            <div className="paynowsec">
              {memberData?.results
                ?.sort((a, b) => a.price - b.price)
                ?.map?.((item) => (
                  <div className="card paynowbtn">
                    <img className="payImg" src="/img/Colored icon.png" />
                    <h2 id="membershipHeading">{item.membership_type}</h2>
                    <h3 className="price">${item.price}</h3>
                    <p key={item.id} onClick={() => handleClick(item.id)}>
                      Pay Now
                    </p>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionPage;
